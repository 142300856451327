import React, { useState } from 'react';
import { useTable, useExpanded, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { Link } from 'react-router-dom';

import { userTags } from '../utils/userTags';

function formatUSD(amount) {
  if (amount === 0 || !amount) {
    return ''
  }
  let roundedAmount = Math.round(amount * 100) / 100;

  return roundedAmount.toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
  });
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  filteredCount,
  uniqueUserCount,
}) {

  const countSpanStyle = {
    float: 'right', 
    padding: '10px 0'
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <input
          value={globalFilter || ''}
          onChange={e => {
            setGlobalFilter(e.target.value || undefined);
          }} 
          placeholder={`Filter by address, asset, tx, etc...`}
          style={{
            fontSize: '1.1rem',
            border: '1px solid black',
            width: '50%',
            marginBottom: 20
          }}
        />
      <p style={countSpanStyle}>
        {uniqueUserCount !== undefined ? `unique address count: ${uniqueUserCount}` : ''}
      </p>
      <p style={countSpanStyle}>
        {filteredCount !== undefined ? `tx count: ${filteredCount}` : ''}
      </p>
    </div>
  );
}

function transformBatchModifyData(batchModifyTx, assetInfo) {
  const modifies = batchModifyTx?.action?.modifies;

  if (!modifies || !Array.isArray(modifies)) {
    console.error('Invalid modifies data:', batchModifyTx);
    return [];
  }
  

  return batchModifyTx.action.modifies.map(modify => {
    const subtype = modify.order.t ? (() => {
      const subtypeKey = Object.keys(modify.order.t)[0];
      const subtypeObj = modify.order.t[subtypeKey];
      const subtypeInnerKey = Object.keys(subtypeObj)[0];
      const subtypeValue = subtypeObj[subtypeInnerKey];
      return `${subtypeKey}: ${subtypeInnerKey}-${subtypeValue}`;
    })() : '';

    return {
      asset: modify.asset ||  assetInfo.get(modify.order.a)?.name || 'Unknown Asset',
      longShort: modify.order.b ? 'Long' : 'Short',
      price: modify.order.p,
      size: modify.order.s,
      amountUSD: parseFloat(modify.order.s) * parseFloat(modify.order.p),
      subtype: subtype
    };
  });
}


function BatchModifySubTable({ row, assetInfo }) {
  const subTableData = transformBatchModifyData(row, assetInfo);

  return (
    <>
      {subTableData.map((subRow, index) => (
        <tr key={index}>
          {/* Map each field in subRow to its corresponding column in the main table */}
          <td/>
          <td/>
          <td className='tac'>{subRow.asset}</td>
          <td className='tac'>order</td>
          <td className='tac'>{subRow.longShort}</td>
          <td className='tac'>{subRow.price}</td>
          <td className='tac'>{subRow.size}</td>
          <td className='tac'>{formatUSD(subRow.amountUSD)}</td>
          <td/>
          <td className='tac'>{subRow.subtype}</td>
          {/* Add more td elements as per your main table's columns */}
        </tr>
      ))}
    </>
  );
}



function TxsTable({ data, assetInfo }) {
  const [expandedDetails, setExpandedDetails] = useState({});
  const columns = React.useMemo(
    () => [
      {
        Header: 'Tx Index',
        accessor: 'index',
        Cell: ({ row: { index } }) => index,
        headerStyle: { textAlign: 'left' }
      },
      {
        Header: 'Transaction Hash',
        accessor: 'hash',
        Cell: ({ value }) => <Link to={`/tx/${value}`} className='hoverable-row'>{value.slice(0, 18) + '...'}</Link>,
        headerStyle: { textAlign: 'left' }
      },
      {
        Header: 'Asset',
        accessor: (row) => {
          let asset;
          if (row.action.type === 'cancel') {
            asset = assetInfo.get(row.action.cancels[0].a).name;
          } else if (row.action.type === 'cancelByCloid') {
            asset = assetInfo.get(row.action.cancels[0].asset).name;
          } else if (row.action.type === 'order') {
            asset = assetInfo.get(row.action.orders[0].a).name;
          } else if (row.action.type === 'batchModify') {
            // no single asset in batch order
            asset = ''
          } else {
            console.error(`unexpected action: ${row.action.type}`);
            console.log('row');
            console.log(row)
            asset = '';
          }
          return asset && asset.name ? asset.name : asset;
        },
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>
      },
      {
        Header: 'Type',
        accessor: 'action.type',
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>
      },
      {
        Header: 'Direction',
        accessor: (row) => {
          if (row.action.type === 'order') {
            if (row.action.orders[0].a < 10_000) {
              return row.action.orders[0].b ? 'Long' : 'Short';
            } else {
              return row.action.orders[0].b ? 'Buy' : 'Sell';
            }
          }
          return '';
        },
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>
      },
      {
        Header: 'Price',
        accessor: (row) => {
          let price;
          if (row.action.type === 'cancel' || row.action.type === 'cancelByCloid') {
            price = row.action.cancels[0].p;
          } else if (row.action.type === 'order') {
            price = row.action.orders[0].p
          } else {
            price = ''
          }
          return price
        },
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>
      },
      {
        Header: 'Size',
        accessor: (row) => {
          let size;
          if (row.action.type === 'cancel' || row.action.type === 'cancelByCloid') {
            size = row.action.cancels[0].s;
          } else if (row.action.type === 'order') {
            size = row.action.orders[0].s
          } else {
            size = ''
          }
          return size
        },
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>
      },
      {
        Header: 'Amount ($)',
        accessor: (row) => {
          let amountUSD;
          if (row.action.type === 'cancel' || row.action.type === 'cancelByCloid') {
            const size = row.action.cancels[0].s;
            const price = row.action.cancels[0].p;

            if (parseFloat(size) && parseFloat(price)) {
              amountUSD = parseFloat(size) * parseFloat(price)
            }
            
          } else if (row.action.type === 'order') {
            const size = row.action.orders[0].s
            const price = row.action.orders[0].p

            if (parseFloat(size) && parseFloat(price)) {
              amountUSD = parseFloat(size) * parseFloat(price)
            }
          } else {
            amountUSD = 0
          }
          return amountUSD
        },
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{formatUSD(value)}</div>
      },
      {
        Header: 'User',
        accessor: 'user',
        filter: 'multiAddress',
        Cell: ({ value }) => <Link to={`https://hypurrscan.io/address/${value}`} target="_blank" rel="noopener noreferrer">{`${value.slice(0, 6)}...${value.slice(-6)}`}</Link>,
        headerStyle: { textAlign: 'left'}
      },
      {
        Header: 'Tag',
        accessor: (row) => {
          return userTags[row?.user] ?? ''
        },
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: 'Subtype',
        accessor: (row) => {
          let subtype;
          if (row.action.type === 'cancel') {
            if ('t' in row.action.cancels[0]) {
              const subtypeObj = row.action.cancels[0].t;
              const subtypeKey = Object.keys(subtypeObj)[0]
              const subtypeValue = Object.keys(subtypeObj[subtypeKey])[0] + '-' + subtypeObj[subtypeKey][Object.keys(subtypeObj[subtypeKey])[0]];
              subtype =  subtypeKey + ': ' + subtypeValue
            }
          } else if (row.action.type === 'order') {
            if ('t' in row.action.orders[0]) {
              const subtypeObj = row.action.orders[0].t;
              const subtypeKey = Object.keys(subtypeObj)[0]
              const subtypeValue = Object.keys(subtypeObj[subtypeKey])[0] + '-' + subtypeObj[subtypeKey][Object.keys(subtypeObj[subtypeKey])[0]];
              subtype = subtypeKey + ': ' + subtypeValue
            }
          } else {
            subtype = ''
          }
          return <div style={{ textAlign: "center" }}>{subtype}</div>;
        },
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>
      },
      {
        Header: () => <div className="tar">Extra Details</div>,
        id: 'expander',
        Cell: ({ row }) => {
          if (row.original.action.type === 'batchModify') {
            return (
              <button onClick={() => setExpandedDetails(old => ({ ...old, [row.id]: !old[row.id] }))}>
                {expandedDetails[row.id] ? 'Hide' : 'Show'} Details
              </button>
            );
          }
        },
      }
    ],
    [assetInfo, expandedDetails, setExpandedDetails]
  );
  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
    rows
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25 },
      globalFilter: (rows, ids, filterValue) => {
        const searchTerms = filterValue.trim().split(',')
            .map(term => term.trim())
            .filter(term => term && !(term.startsWith('-') && term.length === 1)) // Ignore incomplete exclusion terms
            .map(term => {
                const isExclusion = term.startsWith('-');
                let pattern = isExclusion ? term.slice(1) : term;
                pattern = pattern.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'); // Escape special characters
                return {
                    regex: new RegExp(pattern, 'i'),
                    isExclusion
                };
            });
    
        if (!searchTerms.length) {
          return rows;
        }
    
        return rows.filter(row => {
          const rowValues = ids.map(id => row.values[id] ?? '')
                          .map(value => value.toString());
    
          const matchesAllInclusion = searchTerms
              .filter(termObj => !termObj.isExclusion)
              .every(termObj => rowValues.some(rowValue => termObj.regex.test(rowValue)));
    
          const matchesAnyExclusion = searchTerms
              .filter(termObj => termObj.isExclusion)
              .some(termObj => rowValues.some(rowValue => termObj.regex.test(rowValue)));
    
          return matchesAllInclusion && !matchesAnyExclusion;
        });
      },    
    },  
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const filteredCount = rows.length;
  const uniqueUserCount = rows.reduce((acc, {values}) => acc.add(values['user']), new Set()).size;

  return (
    <div style={{marginTop: 10}}>
      <h3>Txs Table</h3>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        filteredCount={filteredCount}
        uniqueUserCount={uniqueUserCount}
      />
      <table style={{marginTop: 0, width: '100%'}} {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th 
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={index === headerGroup.headers.length - 1 ? 'last-th' : ''}
                style={column.headerStyle}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map(row => {
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                <tr  
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => (
                    <td 
                    {...cell.getCellProps()}
                    className={index === row.cells.length - 1 ? 'pr-0' : ''}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
                {row.original.action.type === 'batchModify' && expandedDetails[row.id] && (
                    <BatchModifySubTable
                      row={row.original}
                      assetInfo={assetInfo}
                    />
                )}
              </React.Fragment>
            );
          })}
      </tbody>
      </table>
      <div>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 25, 50, 100, 200, 1000].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default TxsTable;