import React from 'react';
import { useNavigate } from 'react-router-dom';

const Banner = ({ text, navigateTo, children }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(navigateTo);
  };

  return (
    <div className='banner' onClick={handleClick} style={{ cursor: 'pointer', backgroundColor: '#f8f9fa', padding: '10px', textAlign: 'center' }}>
      {text}
      {children}
    </div>
  );
};

export default Banner;
