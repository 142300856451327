import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTable } from 'react-table';

import Banner from '../components/Banner'
import HyperliquidWebSocketClient from '../clients/HyperliquidWebSocketClient';

const Home = () => {
  const [blockNumber, setBlockNumber] = useState('');
  const [blocks, setBlocks] = useState([]);
  const [tps, setTps] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const client = new HyperliquidWebSocketClient();
    client.subscribe('explorerBlock', (newBlocks) => {
      if (newBlocks && newBlocks.length) {
        setBlocks((prevBlocks) => {
          const updatedBlocks = [...newBlocks.flat(), ...prevBlocks];
          if (updatedBlocks.length > 300) {
            updatedBlocks.length = 300; // Keep only the most recent 300 blocks
          }
          calculateTps(updatedBlocks);
          return updatedBlocks;
        });
      }
    });
    return () => {
      client.unsubscribe('explorerBlock');
      client.close();
    };
  }, []);

  const calculateTps = (blocks) => {
    const totalTransactions = blocks.reduce((acc, block) => acc + block.numTxs, 0);
    const timeSpanSeconds = (new Date(blocks[0].blockTime) - new Date(blocks.at(-1).blockTime)) / 1000;
    setTps(totalTransactions / timeSpanSeconds);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Block Time',
        accessor: 'blockTime',
        Cell: ({ value }) => <div>{new Date(value).toISOString()}</div>
      },
      {
        Header: 'Height',
        accessor: 'height',
      },
      {
        Header: 'Num Txs',
        accessor: 'numTxs',
        Cell: ({ value }) => <div style={{textAlign: 'center'}}>{value}</div>
      },
      {
        Header: 'Proposer',
        accessor: 'proposer',
      },
      {
        Header: 'Hash',
        accessor: 'hash',
        // Cell: ({ value }) => value.slice(0, 18) + '...',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: blocks.slice(0, 10) });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (blockNumber) {
      navigate('/block/' + blockNumber);
    }
  };

  return (
    <div className='container'>
      <Banner text="Visualize Multiple Live Books!" navigateTo="/books" />
      <div className='inner-container'>
        <div className='search-container'>
          <h1>Hyperliquid Explorer</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="number"
              value={blockNumber}
              onChange={(e) => setBlockNumber(e.target.value)}
              placeholder="Enter Block Number"
              required
            />
            <button type="submit" className='search-btn'>Search</button>
          </form>
        </div>
        {tps > 0 && <div className='tps-container'>
          <h2>Transactions per Second: {tps.toFixed(2)}</h2>
        </div>}
        {blocks.length > 0 && <div className='blocks-container'>
          <h2>Recent Blocks</h2>
          <table className='recent-blocks' {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className='hoverable-row'>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>
                          <Link to={`/block/${row.original.height}`} className='block-link'>
                            {cell.render('Cell')}
                          </Link>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        }
        <div className='footer'>
          <p>made by <a className='personal-link' href="https://twitter.com/rajivpoc" target="_blank" rel="noopener noreferrer">@rajivpoc</a></p>
          <p style={{marginBottom: 0}}>built using the hyperliquid explorer api. full credit to hyperliquid core devs.</p>
          <p style={{marginTop: 0, marginBottom: 0}}>only block level info for now, more soon. if u have feature requests <a className='personal-link' href="https://www.rajivpoc.com/about" target="_blank" rel="noopener noreferrer">pls reach out</a></p>
        </div>
      </div>
    </div>
  );
};

export default Home;